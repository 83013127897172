<template>
  <app_menu
      :lang="lang"
      :activeLang="activeLang"
      @change-active-lang="whatLangIsActive"
  ></app_menu>
  <back-to-top v-if="showBacToTop" text="">
    <img src="/img/backToTop.svg" alt="" />
  </back-to-top>
<!--  <section class="dark_bg" id="blockOne">-->
<!--    <div class="ball ball1"></div>-->
<!--    <div class="ball ball2"></div>-->
<!--    <div class="container">-->
<!--      <div class="row">-->
<!--        <div class="col blockOne__lang center-x">-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <div class="col center-x">-->
<!--          <img src="@/assets/img/mainLogo.svg" alt="Logo">-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <div class="col center-x">-->
<!--          <div class="arrow">-->
<!--             <img  src="@/assets/img/icons/scroll_down.png" alt="icon bottom">-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->
  <section class="light_bg" id="blockTwo">
    <div class="container">
      <div class="row text_content">
        <div class="col">
          <h2>{{ data.blockTwo.title }}</h2>
          <p>{{ data.blockTwo.p[0] }}</p>
        </div>
        <div class="col" v-if="!isSmall">
          <a class="download" href="./assets/download/Logo_main.zip">{{ data.button }}</a>
        </div>
      </div>
      <div class="row logo_content">
        <div class="col-xl-6 col-md-12">
          <div class="logo_content__die_dark center-x">
            <img src="@/assets/img/mainLogo.svg" alt="Logo">
          </div>
        </div>
        <div class="col-xl-6 col-md-12">
          <div class="col logo_content__die_light center-x">
            <img src="@/assets/img/logo2.svg" alt="Logo">
          </div>
        </div>
        <div class="col" v-if="isSmall" style="height: 75px">
          <a class="download" href="./assets/download/Logo_main.zip">{{ data.button }}</a>
        </div>
      </div>
    </div>
  </section>
  <section class="light_bg" id="blockThree">
    <div class="container">
      <div class="row text_content">
        <div class="col-md-9 col-sm-12">
          <h2>{{ data.blockThree.title }}</h2>
          <p>{{ data.blockThree.p[0] }}</p>
        </div>
        <div class="col"  v-if="!isSmall">
          <a class="download" href="./assets/download/Logo_monochrome.zip">{{ data.button }}</a>
        </div>
      </div>
      <div class="row logo_content">
        <div class="col-xl-6 col-md-12">
          <div class="logo_content__die_gray center-x">
            <img src="@/assets/img/logoWight.svg" alt="Logo">
          </div>
        </div>
        <div class="col-xl-6 col-md-12">
          <div class="col logo_content__die_light center-x">
            <img src="@/assets/img/logoBlack.svg" alt="Logo">
          </div>
        </div>
        <div class="col" v-if="isSmall" style="height: 75px">
          <a class="download" href="./assets/download/Logo_monochrome.zip">{{ data.button }}</a>
        </div>
      </div>
    </div>
  </section>
  <section class="dark_bg" id="blockFour">
    <div class="ball ball1"></div>
    <div class="ball ball2"></div>
    <div class="container">
      <div class="row text_content">
        <div class="col-xl-8 col-md-12">
          <h2>{{ data.blockFour.title }}</h2>
          <p>{{ data.blockFour.p[0] }}</p>
        </div>
      </div>
      <div class="row logo_content">
        <div class="col">
          <div class="center-x">
            <img src="@/assets/img/logo5.svg" alt="Logo">
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="light_bg" id="blockFive">
    <div class="container">
      <div class="row text_content">
        <div class="col-xl-8 col-md-12">
          <h2>{{ data.blockFive.title }}</h2>
          <p>{{ data.blockFive.p[0] }}</p>
        </div>
      </div>
      <div class="row logo_content">
        <div class="col">
          <div class="center-x">
            <img src="@/assets/img/logo6.svg" alt="Logo">
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="dark_bg" id="blockSix">
    <div class="ball ball1"></div>
    <div class="ball ball2"></div>
    <div class="container">
      <div class="row text_content">
        <div class="col-md-8 col-sm-12">
          <h2>{{ data.blockSix.title }}</h2>
          <p v-for="(text, i) in data.blockSix.p" :key="i">{{ text }}</p>
        </div>
        <div class="col"  v-if="!isSmall">
          <a class="download" href="./assets/download/Dynamic_safety_zone.zip">{{ data.button }}</a>
        </div>
      </div>
      <div class="row logo_content">
        <div class="col">
          <div class="center-x">
            <img src="@/assets/img/logo7.svg" alt="Logo">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col" v-if="isSmall" style="margin-top: 50px; height: 75px">
          <a class="download" href="./assets/download/Dynamic_safety_zone.zip">{{ data.button }}</a>
        </div>
      </div>
    </div>
  </section>
  <section class="light_bg" id="blockSeven">
    <div class="container">
      <div class="row text_content">
        <div class="col-xl-8 col-md-12">
          <h2>{{ data.blockSeven.title }}</h2>
          <p>{{ data.blockSeven.p[0] }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6 col-md-12">
          <div class="color__wrap col-wrap-6 light_gradient">
            <div class="color__content" v-for="(el, i) in data.gradients[0]" :key="i">
              <p><span>HEX: </span>{{ el.HEX }}</p>
              <p><span>CMYK: </span>{{ el.CMYK }}</p>
              <p><span>Pantone </span>{{ el.Pantone }}</p>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-md-12">
          <div class="color__wrap col-wrap-6 dark_gradient">
            <div class="color__content" v-for="(el, i) in data.gradients[1]" :key="i">
              <p><span>HEX: </span>{{ el.HEX }}</p>
              <p><span>CMYK: </span>{{ el.CMYK }}</p>
              <p><span>Pantone </span>{{ el.Pantone }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="margin-top: 55px; margin-bottom: 30px;">
        <div class="col-xl-12"><p>{{ data.blockSeven.p[1] }}</p>
          <p>{{ data.blockSeven.p[2] }}</p>
        </div>
      </div>
      <div class="row">
        <div
            class="col-xl-2 col-md-4"
            v-for="(el, i) in data.colors"
            :key="i"
        >
          <div
              class="color__wrap col-wrap-1"
              :style="`
                background: var(--BackgroundSubstrateOptionalColors${ i + 1 });
                ${ el.textColor === 'wight' ? 'color: var(--variable-lightColorText)' : '' }
              `"
          >
            <div class="color__content">
              <p><span>HEX: </span>{{ el.HEX }}</p>
              <p><span>CMYK: </span>{{ el.CMYK }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="light_bg" id="blockEight">
    <div class="container">
      <div class="row text_content">
        <div class="col">
          <h2>{{ data.blockEight.title }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="myCol">
          <div class="myRow"><p>{{ data.blockEight.p[0] }}</p></div>
          <div class="myRow">
            <div class="myCol"><img src="@/assets/img/logo17.svg" alt="logo"></div>
            <div class="myCol"><img src="@/assets/img/logo18.svg" alt="logo"></div>
          </div>
          <div class="myRow"><p>{{ data.blockEight.p[1] }}</p></div>
          <div class="myRow">
            <div class="myCol"><img src="@/assets/img/logo19.svg" alt="logo"></div>
            <div class="myCol"><img src="@/assets/img/logo20.svg" alt="logo"></div>
          </div>
        </div>
        <div class="myCol">
          <div class="myRow"><p>{{ data.blockEight.p[2] }}</p></div>
          <div class="myRow">
            <div class="myCol"><img src="@/assets/img/logo20.png" alt="logo"></div>
          </div>
          <div class="myRow"><p>{{ data.blockEight.p[3] }}</p></div>
          <div class="myRow">
            <div class="myCol"><img src="@/assets/img/logo21.svg" alt="logo"></div>
          </div>
        </div>
        <div class="myCol">
          <div class="myRow"><p>{{ data.blockEight.p[4] }}</p></div>
          <div class="myRow"><div class="myCol"><img src="@/assets/img/logo22.png" alt="logo"></div></div>
        </div>
      </div>
    </div>
  </section>
  <section class="dark_bg" id="blockNine">
    <div class="ball ball1"></div>
    <div class="ball ball2"></div>
    <div class="container">
      <div class="row text_content">
        <div class="col-xl col-md-9 col-sm-12">
          <h2>{{ data.blockNine.title }}</h2>
          <h3>{{ data.blockNine.p[0] }}</h3>
          <p>{{ data.blockNine.p[1] }}</p>
        </div>
        <div class="col" v-if="!isSmall">
          <a class="download" href="./assets/download/Sign.zip">{{ data.button }}</a>
        </div>
      </div>
      <div class="row">
        <div class="col" style="display: flex; justify-content: center;">
          <img v-if="!isSmall" src="@/assets/img/3logos.svg" alt="logo">
          <img v-if="isSmall" src="@/assets/img/3logos_sm.svg" alt="logo">
        </div>
      </div>
      <div class="row">
        <div class="col" v-if="isSmall" style="margin-top: 50px; height: 75px">
          <a class="download" href="./assets/download/Dynamic_safety_zone.zip">{{ data.button }}</a>
        </div>
      </div>
    </div>
  </section>
  <section class="light_bg" id="blockTen">
    <div class="container">
      <div class="row text_content">
        <div class="col-xl-9 col-md-12">
          <h2>{{ data.blockTen.title }}</h2>
          <p>{{ data.blockTen.p[0] }}</p>
          <p>{{ data.blockTen.p[1] }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6 col-md-12">
          <div class="die_light">
            <div class="row">
              <div class="col"><span>{{ data.blockTen.p[2] }}</span></div>
            </div>
            <div class="row">
              <div class="col center-x font gilroy">Gilroy</div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-md-12">
          <div class="die_light">
            <div class="row">
              <div class="col"><span>{{ data.blockTen.p[3] }}</span></div>
            </div>
            <div class="row">
              <div class="col center-x font pressGothic">Press Gothic</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BackToTop from 'vue-backtotop';
import app_menu from '@/components/app_menu';
import dataL from '@/assets/lang'
import gsap from "gsap"

export default {
  name: 'App',
  data() {
    return {
      lang: ['ru', 'en'],
      data: dataL.ru,
      activeLang: 'ru',
      isSmall: false
    }
  },
  methods: {
    isLangActive(lang = 'ru') {
      return this.activeLang === lang ? 'active' : ''
    },
    whatLangIsActive(lang) {
      this.activeLang = lang
    }
  },
  watch: {
    activeLang() {
      this.data = dataL[this.activeLang]
    }
  },
  components: {
    app_menu,
    BackToTop,
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    showBacToTop() {
      return document.documentElement.clientWidth >= 576;
    },
  },
  mounted() {
    // анимация стрелочки в первой секции
    // let tl = gsap.timeline({repeat: -1});
    // tl.to(".arrow", {
    //   y: 100,
    //   opacity: .5,
    //   duration: 2,
    //   ease: "bounce.out"
    // });
    // tl.to(".arrow", {
    //   y: 0,
    //   opacity: 1,
    //   duration: 2
    // });

    // анимация темного фона
    let tlBall = gsap.timeline({repeat: -1});
    tlBall.to(".ball1", {
      y: '100vh',
      x: '-100vw',
      duration: 10
    });
    tlBall.to(".ball1", {
      y: 0,
      x: 0,
      duration: 10
    });

    // анимация темного фона
    let tlBall2 = gsap.timeline({repeat: -1});
    tlBall2.to(".ball2", {
      y: '-100vh',
      x: '100vw',
      duration: 10
    });
    tlBall2.to(".ball2", {
      y: 0,
      x: 0,
      duration: 10
    });

    window.addEventListener('resize', () => {
      this.isSmall = document.documentElement.clientWidth < 800;
    })
  }
}
</script>

<style lang="scss" scoped></style>
