const data = {
	ru: {
		button: 'Скачать',
		gradients: [
			[
				{
					HEX: '#46F5F4',
					CMYK: '61 0 15 0',
					Pantone: '2198C'
				},
				{
					HEX: '#68FFA7',
					CMYK: '40 0 40 0',
					Pantone: '2255C'
				}
			],
			[
				{
					HEX: '#55063A',
					CMYK: '8 100 45 38',
					Pantone: '221C'
				},
				{
					HEX: '#11021C',
					CMYK: '95 98 25 60',
					Pantone: '5255C'
				},
				{
					HEX: '#004856',
					CMYK: '100 9 40 44',
					Pantone: '315C'
				}
			]
		],
		colors: [
			{ HEX: '#46F5F4', CMYK: '61 0 15 0', textColor: '' },
			{ HEX: '#68FFA7', CMYK: '40 0 40 0', textColor: '' },
			{ HEX: '#55063A', CMYK: '8 100 45 38', textColor: 'wight' },
			{ HEX: '#11021C', CMYK: '95 98 25 60', textColor: 'wight' },
			{ HEX: '#004856', CMYK: '100 9 40 44', textColor: 'wight' },
			{ HEX: '#FFEB00', CMYK: '12 0 84 0', textColor: '' }
		],
		blockTwo: {
			title: 'Логотип',
			p: [
				'Основные версии логотипа'
			]
		},
		blockThree: {
			title: 'Монохромные версии',
			p: [
				'Использовать инверсию и монохром допустимо только в связи с внешним ограничением цветопередачи (например, при размещении логотипа на печатной документации)'
			]
		},
		blockFour: {
			title: 'Безопасное поле',
			p: [
				'Для обеспечения правильного отображения логотипа необходимо строго учитывать размер охранного поля. В этой зоне не должно находиться никаких графических элементов.'
			]
		},
		blockFive: {
			title: 'Размеры',
			p: [
				'Минимально допустимый размер основного логотипа по ширине - 15мм/50px'
			]
		},
		blockSix: {
			title: 'Плашка',
			p: [
				'Используется в случае необходимости размещения логотипа на конфликтном бекграунде:',
				'- имеющего среднюю тональность;',
				'- фотографии с повышенной резкостью и детализацией;',
				'- в цветовой гамме, не соответствующей брендовым цветам.'
			]
		},
		blockSeven: {
			title: 'Использование цвета',
			p: [
				'Основным цветовым вариантом является градиентное окрашивание логотипа. В самом логотипе запрещено менять направление градиента или менять пропорции заливки.',
				'Логотип имеет пять основных цветов.',
				'Так же используется дополнительный акцентный цвет - жёлтый.',
			]
		},
		blockEight: {
			title: 'Ограничения',
			p: [
				'Нельзя менять ширину, высоту и каким-либо образом деформировать логотип.',
				'Нельзя наклонять и отзеркаливать логотип, а также отдельные его элементы.',
				'Нельзя менять цвет логотипа и плашки, а также их размер отдельно друг от друга.',
				'Нельзя использовать другой шрифт.',
				'Запрещено использовать логотип без плашки на конфликтных бекграундах.'
			]
		},
		blockNine: {
			title: 'Знак',
			p: [
				'Основные версии знаков',
				'При необходимости возможно использование графических элементов отдельно друг от друга (например, если необходимо использование логотипа в размере, меньше допустимого).  Правила использования для знаков такие же, как и для полной версии логотипа.'
			]
		},
		blockTen: {
			title: 'Шрифт',
			p: [
				'Основной шрифт для набора Gilroy и его начертания.',
				'Для заголовков афиш и выделения информации используется декоративный шрифт Press Gothic.',
				'Основной',
				'Дополнительный'
			]
		}
	},
	en: {
		button: 'Download',
		gradients: [
			[
				{
					HEX: '#46F5F4',
					CMYK: '61 0 15 0',
					Pantone: '2198C'
				},
				{
					HEX: '#68FFA7',
					CMYK: '40 0 40 0',
					Pantone: '2255C'
				}
			],
			[
				{
					HEX: '#55063A',
					CMYK: '8 100 45 38',
					Pantone: '221C'
				},
				{
					HEX: '#11021C',
					CMYK: '95 98 25 60',
					Pantone: '5255C'
				},
				{
					HEX: '#004856',
					CMYK: '100 9 40 44',
					Pantone: '315C'
				}
			]
		],
		colors: [
			{ HEX: '#46F5F4', CMYK: '61 0 15 0', textColor: '' },
			{ HEX: '#68FFA7', CMYK: '40 0 40 0', textColor: '' },
			{ HEX: '#55063A', CMYK: '8 100 45 38', textColor: 'wight' },
			{ HEX: '#11021C', CMYK: '95 98 25 60', textColor: 'wight' },
			{ HEX: '#004856', CMYK: '100 9 40 44', textColor: 'wight' },
			{ HEX: '#FFEB00', CMYK: '12 0 84 0', textColor: '' }
		],
		blockTwo: {
			title: 'Logo',
			p: [
				'Main versions of the logo'
			]
		},
		blockThree: {
			title: 'Monochrome versions',
			p: [
				'The use of inversion and monochrome is allowed only due to an external limitation of color rendering (for example, when placing a logo on printed documentation).'
			]
		},
		blockFour: {
			title: 'Safe zone',
			p: [
				'To ensure the correct display of the logo, it is necessary to strictly take into account the size of the safe zone. There should be no graphic elements in this zone.'
			]
		},
		blockFive: {
			title: 'Sizes',
			p: [
				'The minimum allowed size of the main logo in width is 15mm/50px.'
			]
		},
		blockSix: {
			title: 'Dynamic safety zone',
			p: [
				'If it is necessary to place the logo on the contrast background:',
				'- having an average tonality;',
				'- photos with increased sharpness and detail;',
				'- in a color scheme that does not correspond to branded colors.'
			]
		},
		blockSeven: {
			title: 'Using color',
			p: [
				'The main color option is gradient coloring of the logo. In the logo itself, it is forbidden to change the direction of the gradient or change the proportions of the fill.',
				'The logo has five primary colors.',
				'An additional accent color is also used - yellow.',
			]
		},
		blockEight: {
			title: 'Limitations',
			p: [
				'You cannot change the width, height or deform the logo in any way.',
				'You cannot tilt and mirror the logo, as well as its individual elements.',
				'You cannot change the color of the logo and a dynamic safety zone, as well as their size separately from each other.',
				'You cannot use a different font.',
				'It is forbidden to use the logo without a dynamic safety zone on a contrast backgrounds.'
			]
		},
		blockNine: {
			title: 'Sign',
			p: [
				'Main versions of signs',
				'If necessary, it is possible to use graphic elements separately from each other (for example, if it is necessary to use a logo in a size smaller than the permissible one). The rules of use for the signs are the same as for the full version of the logo.'
			]
		},
		blockTen: {
			title: 'Font',
			p: [
				'The main font for the Gilroy set and its typeface.',
				'The decorative font Press Gothic is used for the headings of posters and highlighting information.',
				'Main font',
				'Additional font'
			]
		}
	}
}
export default data